<template>
  <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
    <router-view />
    <div class="container text-center py-2">
      <b-link :to="{ name: 'auth-imprint' }" tabindex="-1">
        <small>Impressum/Imprint</small>
      </b-link>
      <b-link :to="{ name: 'auth-privacypolicy' }" tabindex="-1" class="d-inline-block ml-1">
        <small>Datenschutzerklärung/Privacy Policy</small>
      </b-link>
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BAlert,
  BButton,
  BCardText, BCardTitle,
  BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BImg, BInputGroup, BInputGroupAppend,
  BLink, BRow,
} from 'bootstrap-vue'
import VuexyLogo from "@core/layouts/components/Logo";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  components: {
    BLink,
  },

  setup() {
    const { contentWidth } = useAppConfig()
    return { contentWidth }
  },
}
</script>
